// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// Vendor node_modules 
var $ = require('jquery');
var jQueryBridget = require('jquery-bridget');


// Vendor node_modules 
// require('@fancyapps/fancybox');
// require('flickity');
require('flickity-imagesloaded');
require('flickity-fade');


var ModalVideo = require('modal-video');
new ModalVideo('.js-modal-btn');


// Scripts
require('./modules/scripts');



	









